import { Status as TagStatus } from 'components/TagStatus/types';
import { ScheduledChangeType, SubscriptionStatus } from 'services/people/types';
import { isFuture, isValid } from 'date-fns';

/* Files */
export const getFileStatusTag = (status: string | null | undefined, errorsCount = 0): TagStatus => {
  switch (status) {
    case 'REJECTED':
      return TagStatus.ERROR;
    case 'ACCEPTED':
    case 'NA':
      return errorsCount ? TagStatus.WARNING : TagStatus.SUCCESS;
    case null:
    case undefined:
      return TagStatus.PENDING;
    case '':
    default:
      return status as TagStatus;
  }
};

export const getFileStatusDisplayName = (
  status: string | null | undefined,
  prefix = ''
): string => {
  const prefixWithSpace = prefix ? `${prefix} ` : '';

  switch (status) {
    case 'ACCEPTED':
      return prefix ? `${prefixWithSpace}accepted` : 'Accepted';
    case 'REJECTED':
      return prefix ? `${prefixWithSpace}rejected` : 'Rejected';
    case 'NA':
      return 'No Action';
    case null:
    case undefined:
      return prefix ? `${prefixWithSpace}processing` : 'Processing';
    case '':
    default:
      return status;
  }
};

export const getFileRecordActionDisplayName = (action: string = ''): string => {
  switch (action) {
    case 'ADD':
      return 'Add Request';
    case 'TERM':
      return `Term Request`;
    case 'CHANGE':
      return `Change Request`;
    case 'SCHEDULED_ADD':
      return `Scheduled Add`;
    case 'SCHEDULED_CHANGE':
      return 'Scheduled Change';
    case 'SCHEDULED_TERM':
      return `Scheduled Term`;
    default:
      return action;
  }
};

export const getFileLabel = (fileName: string): string =>
  fileName.startsWith('test_') ? 'Test File' : 'Production File';

function capitalize(str: string) {
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}

/* Members */
export const getMemberDisplayName = (
  status: SubscriptionStatus,
  scheduledProps: {
    scheduledEffectiveDate?: string;
    scheduledChangeType?: ScheduledChangeType;
  }
): string => {
  const isScheduled =
    scheduledProps?.scheduledEffectiveDate &&
    isValid(new Date(scheduledProps.scheduledEffectiveDate)) &&
    isFuture(new Date(scheduledProps.scheduledEffectiveDate));

  switch (status) {
    case SubscriptionStatus.PENDING:
    case SubscriptionStatus.PROVISIONING:
      return 'Provisioning';
    case SubscriptionStatus.FAILED:
      return 'Provisioning Failure';
    case SubscriptionStatus.CLOSED:
      return 'Closed';
    case SubscriptionStatus.RECORD_FAILURE:
      return 'Record Failure';
    case SubscriptionStatus.PENDING_ENROLLMENT:
      return 'Pending Enrollment (Scheduled)';
    case SubscriptionStatus.PENDING_REQUEST:
      return 'Pending Request';
    case SubscriptionStatus.ENROLLED:
      return 'Enrolled';
    case SubscriptionStatus.ELIGIBLE:
      return 'Loaded';
    case SubscriptionStatus.EXPIRED:
      return 'Expired';
    case SubscriptionStatus.TERMINATED:
      return 'Terminated';
    case SubscriptionStatus.PORTED:
      return 'Ported';
    case SubscriptionStatus.ACTIVE:
      return isScheduled ? getScheduledStatusName(scheduledProps.scheduledChangeType) : 'Active';
    case 'NULL':
      return isScheduled ? getScheduledStatusName(scheduledProps.scheduledChangeType) : '';
    default:
      return status && capitalize(status);
  }
};

export const getScheduledStatusName = (scheduledType: ScheduledChangeType | undefined) => {
  switch (scheduledType) {
    case ScheduledChangeType.ADD:
      return 'Scheduled Add';
    case ScheduledChangeType.CHANGE:
      return 'Scheduled Change';
    case ScheduledChangeType.TERMINATE:
      return 'Scheduled Terminate';
    default:
      return '';
  }
};
